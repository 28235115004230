import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "../store/app/user/index";

function guard(to, from, next) {
	useUserStore().token ? next() : next("/login");
}

const routes = [
	// {
	// 	path: "/",
	// 	name: "Home",
	// 	component: () => import("@/views/auth/login/index"),
	// },
	{
		path: "/",
		beforeEnter: (to, from, next) => {
			useUserStore().token ? next("/dashboard") : next();
		},
		name: "Login",
		component: () => import("@/views/auth/login/index"),
	},
	{
		path: "/login",
		beforeEnter: (to, from, next) => {
			useUserStore().token ? next("/dashboard") : next();
		},
		name: "Login",
		component: () => import("@/views/auth/login/index"),
	},
	{
		path: "/logout",
		name: "Logout",
		component: () => import("@/views/auth/logout/index"),
	},
	{
		path: "/forgot_password",
		name: "Forgot Password",
		component: () => import("@/views/auth/forgot/forgot.vue"),
		meta: {
			hide: true,
			page_title: "Forgot Password",
		},
	},
	{
		path: "/reset-password/:token",
		name: "Reset Password",
		component: () => import("@/views/auth/forgot/reset.vue"),
		meta: {
			hide: true,
			page_title: "Reset Password",
		},
	},
	{
		path: "/",
		// name: "Layout",
		redirect: "/login",
		component: () => import("@/Layout/index.vue"),
		beforeEnter: guard,
		children: [
			{
				path: "dashboard",
				name: "dashboard",
				component: () => import("@/views/dashboard/index.vue"),
				meta: {
					hide: true,
				},
			},

			//RESERVATION//
			{
				path: "manage_booking/flights",
				name: "Manage Flights Reservation",
				component: () =>
					import("@/views/manage_booking/flights/ViewFlights.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/flights/confirmation/:id",
				name: "Flights Reservation Detail",
				// component: () =>
				// 	import("@/views/reservation/flights/FlightDetailsGDS.vue"),
				component: () =>
					import("@/views/manage_booking/flights/FlightDetailsDB.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/hotels",
				name: "Manage Hotels Reservation",
				component: () => import("@/views/manage_booking/hotels/ViewHotels.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/hotels/confirmation/:id",
				name: "Hotels Reservation Detail",
				component: () =>
					import("@/views/manage_booking/hotels/HotelDetails.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/packages",
				name: "Manage Packages Reservation",
				component: () =>
					import("@/views/manage_booking/packages/ViewPackage.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/packages/confirmation/:id",
				name: "Package Reservation Detail",
				component: () =>
					import("@/views/manage_booking/packages/PackageDetails.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/activities",
				name: "Manage Activities Reservation",
				component: () =>
					import("@/views/manage_booking/activities/ViewActivities.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/activities/confirmation/:id",
				name: "Activities Reservation Detail",
				component: () =>
					import("@/views/manage_booking/activities/ActivitiesDetails.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/insurance",
				name: "Manage Insurance Reservation",
				component: () =>
					import("@/views/manage_booking/insurance/ViewInsurance.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/insurance/confirmation/:id",
				name: "Insurance Reservation Detail",
				component: () =>
					import("@/views/manage_booking/insurance/InsuranceDetails.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/ferry",
				name: "Manage Ferry Reservation",
				component: () => import("@/views/manage_booking/ferry/ViewFerry.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "manage_booking/ferry/confirmation/:id",
				name: "Ferry Reservation Detail",
				component: () =>
					import("@/views/manage_booking/ferry/FerryDetails.vue"),
				meta: {
					hide: true,
				},
			},

			//ADMINISTRATION

			//VACATION PACKAGES
			{
				path: "packages",
				name: "Vacation Packages",
				component: () => import("@/views/packages/ViewPackage.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/add",
				name: "Add Package",
				component: () => import("@/views/packages/AddPackage.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/edit/:uuid",
				name: "Edit Package",
				component: () => import("@/views/packages/EditPackage.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/details/:uuid",
				name: "Package Details",
				component: () => import("@/views/packages/PackageDetails.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/categories",
				name: "Vacation Categories",
				component: () => import("@/views/packages/category/ViewCategory.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/categories/add",
				name: "Add Category",
				component: () => import("@/views/packages/category/AddCategory.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "packages/categories/edit/:uuid",
				name: "Edit Category",
				component: () => import("@/views/packages/category/EditCategory.vue"),
				meta: {
					hide: true,
				},
			},

			//FERRY MANAGEENT
			{
				path: "ferry",
				name: "Ferry Routes",
				component: () => import("@/views/ferry/routes/ViewRoute.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/routes",
				name: "Ferry Routes",
				component: () => import("@/views/ferry/routes/ViewRoute.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/routes/add",
				name: "Add Ferry Route",
				component: () => import("@/views/ferry/routes/AddRoute.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/routes/edit/:uuid",
				name: "Edit Route",
				component: () => import("@/views/ferry/routes/EditRoute.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/schedule",
				name: "Ferry Schedule",
				component: () => import("@/views/ferry/schedule/ViewSchedule.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/schedule/add",
				name: "Add Schedule",
				component: () => import("@/views/ferry/schedule/AddSchedule.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "ferry/schedule/edit/:uuid",
				name: "Edit Schedule",
				component: () => import("@/views/ferry/schedule/EditSchedule.vue"),
				meta: {
					hide: true,
				},
			},

			//FLIGHT OFFER BOXES
			{
				path: "offer_boxes",
				name: "Flight Offers",
				component: () => import("@/views/offer_boxes/flights/ViewFlight.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/flights",
				name: "Flight Offers",
				component: () => import("@/views/offer_boxes/flights/ViewFlight.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/flights/add",
				name: "Add Flight Offer",
				component: () => import("@/views/offer_boxes/flights/AddFlight.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/flights/edit/:uuid",
				name: "Edit Flight Offer",
				component: () => import("@/views/offer_boxes/flights/EditFlight.vue"),
				meta: {
					hide: true,
				},
			},

			//HOTEL OFFER BOXES
			{
				path: "offer_boxes",
				name: "Hotel Offers",
				component: () => import("@/views/offer_boxes/hotels/ViewHotel.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/hotels",
				name: "Hotel Offers",
				component: () => import("@/views/offer_boxes/hotels/ViewHotel.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/hotels/add",
				name: "Add Hotel Offer",
				component: () => import("@/views/offer_boxes/hotels/AddHotel.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "offer_boxes/hotels/edit/:uuid",
				name: "Edit Hotel Offer",
				component: () => import("@/views/offer_boxes/hotels/EditHotel.vue"),
				meta: {
					hide: true,
				},
			},

			// AGENCY MANAGEMENT
			{
				path: "administration",
				name: "Adiministration",
				component: () =>
					import(
						"@/views/administration/agency_management/EditAgencyManagement.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/agency_management",
				name: "Agency Management",
				component: () =>
					import(
						"@/views/administration/agency_management/EditAgencyManagement.vue"
					),
				meta: {
					hide: true,
				},
			},
			// ABOUT US
			{
				path: "administration/about_us",
				name: "About Us",
				component: () =>
					import("@/views/administration/about_us/EditAboutUs.vue"),
				meta: {
					hide: true,
				},
			},
			// SOCIAL MEDIA
			{
				path: "administration/social_media",
				name: "Social Media",
				component: () =>
					import("@/views/administration/social_media/EditSocialMedia.vue"),
				meta: {
					hide: true,
				},
			},
			// SERVICES
			{
				path: "administration/services",
				name: "Services",
				component: () =>
					import("@/views/administration/services/ViewServices.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/services/add",
				name: "Add Service",
				component: () =>
					import("@/views/administration/services/AddService.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/services/edit/:uuid",
				name: "Edit Service",
				component: () =>
					import("@/views/administration/services/EditService.vue"),
				meta: {
					hide: true,
				},
			},
			// AGENCY MANAGEMENT
			{
				path: "administration/agency_management/terms_conditions",
				name: "Terms and Conditions",
				component: () =>
					import(
						"@/views/administration/agency_management/TermsConditions.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/agency_management/privacy_policy",
				name: "Privacy Policy",
				component: () =>
					import("@/views/administration/agency_management/PrivacyPolicy.vue"),
				meta: {
					hide: true,
				},
			},
			// AGENCY MANAGEMENT

			//CURRENCY CONVERSION//
			{
				path: "administration/currency",
				name: "Currency",
				component: () =>
					import("@/views/administration/currency/ViewCurrency.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/currency/add",
				name: "Add Currency",
				component: () =>
					import("@/views/administration/currency/AddCurrency.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/currency/edit/:uuid",
				name: "Edit Currency",
				component: () =>
					import("@/views/administration/currency/EditCurrency.vue"),
				meta: {
					hide: true,
				},
			},
			//CURRENCY CONVERSION//

			// FLIGHT RULE
			{
				path: "administration/flight_rule",
				name: "Flight Rule",
				component: () =>
					import("@/views/administration/flight_rule/ViewFlightRule.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/flight_rule/add",
				name: "Add Flight Rule",
				component: () =>
					import("@/views/administration/flight_rule/AddFlightRule.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/flight_rule/edit/:uuid",
				name: "Edit Flight Rule",
				component: () =>
					import("@/views/administration/flight_rule/EditFlightRule.vue"),
				meta: {
					hide: true,
				},
			},
			// FLIGHT RULE

			// HOTEL MARKUP
			{
				path: "administration/hotel_markup",
				name: "Hotel Markup",
				component: () =>
					import("@/views/administration/hotel_markup/ViewHotelMarkup.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/hotel_markup/add",
				name: "Add Hotel Markup",
				component: () =>
					import("@/views/administration/hotel_markup/AddHotelMarkup.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/hotel_markup/edit/:uuid",
				name: "Edit Hotel Markup",
				component: () =>
					import("@/views/administration/hotel_markup/EditHotelMarkup.vue"),
				meta: {
					hide: true,
				},
			},
			// HOTEL MARKUP

			// ACTIVITY MARKUP
			{
				path: "administration/activity_markup",
				name: "Activity Markup",
				component: () =>
					import(
						"@/views/administration/activities_markup/ViewActivityMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/activity_markup/add",
				name: "Add Activity Markup",
				component: () =>
					import(
						"@/views/administration/activities_markup/AddActivityMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/activity_markup/edit/:uuid",
				name: "Edit Activity Markup",
				component: () =>
					import(
						"@/views/administration/activities_markup/EditActivityMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			// ACTIVITY MARKUP

			// INSURANCE MARKUP
			{
				path: "administration/insurance_markup",
				name: "Insurance Markup",
				component: () =>
					import(
						"@/views/administration/insurance_markup/ViewInsuranceMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/insurance_markup/add",
				name: "Add Insurance Markup",
				component: () =>
					import(
						"@/views/administration/insurance_markup/AddInsuranceMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/insurance_markup/edit/:uuid",
				name: "Edit Insurance Markup",
				component: () =>
					import(
						"@/views/administration/insurance_markup/EditInsuranceMarkup.vue"
					),
				meta: {
					hide: true,
				},
			},
			// INSURANCE MARKUP

			//HOME SLIDER
			{
				path: "administration/home_slider",
				name: "Home Slider",
				component: () =>
					import("@/views/administration/home_slider/EditHomeSlider.vue"),
				meta: {
					hide: true,
				},
			},

			// EMAIL TEMPLATE
			{
				path: "administration/email_template",
				name: "Email Template",
				component: () =>
					import("@/views/administration/email_template/EditEmailTemplate.vue"),
				meta: {
					hide: true,
				},
			},
			// EMAIL TEMPLATE

			// CSS JAVASCRIPT
			{
				path: "administration/scripts",
				name: "Scripts",
				component: () =>
					import("@/views/administration/scripts/EditScripts.vue"),
				meta: {
					hide: true,
				},
			},
			// CSS JAVASCRIPT

			// BANK ACCCOUNT
			{
				path: "administration/bank_account",
				name: "Bank Account",
				component: () =>
					import("@/views/administration/bank_account/ViewBankAccount.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/bank_account/add",
				name: "Add Bank Account",
				component: () =>
					import("@/views/administration/bank_account/AddBankAccount.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/bank_account/edit/:uuid",
				name: "Edit Bank Account",
				component: () =>
					import("@/views/administration/bank_account/EditBankAccount.vue"),
				meta: {
					hide: true,
				},
			},
			// BANK ACCCOUNT

			// USER MANAGEMENT
			{
				path: "administration/user_management",
				name: "User Management",
				component: () =>
					import("@/views/administration/user_management/ViewUser.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/user_management/add",
				name: "Add User",
				component: () =>
					import("@/views/administration/user_management/AddUser.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "administration/user_management/edit/:uuid",
				name: "Edit User",
				component: () =>
					import("@/views/administration/user_management/EditUser.vue"),
				meta: {
					hide: true,
				},
			},
			// USER MANAGEMENT

			//CUSTOMERS
			{
				path: "/customers",
				name: "Customers",
				component: () =>
					import("@/views/customers/ViewCustomers.vue"),
				meta: {
					hide: true,
				},
			},
			{
				path: "/customers/bookings",
				name: "Customer Details",
				component: () => import("@/views/customers/CustomerDetails.vue"),
				meta: {
					hide: true,
				},
				children: [
					{
						path: "flights/:uuid",
						name: "Flights Bookings",
						component: () =>
							import("@/views/customers/bookings/flights/ViewFlights.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "flights/details/:uuid",
						name: "Flights Bookings Detail",
						component: () =>
							import("@/views/customers/bookings/flights/FlightDetailsDB.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "hotels/:uuid",
						name: "Hotel Bookings",
						component: () =>
							import("@/views/customers/bookings/hotels/ViewHotels.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "hotels/details/:uuid",
						name: "Hotel Bookings Detail",
						component: () =>
							import("@/views/customers/bookings/hotels/HotelDetails.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "insurance/:uuid",
						name: "Insurance Bookings",
						component: () =>
							import("@/views/customers/bookings/insurance/ViewInsurance.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "insurance/details/:uuid",
						name: "Insurance Bookings Detail",
						component: () =>
							import(
								"@/views/customers/bookings/insurance/InsuranceDetails.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "packages/:uuid",
						name: "Packages Bookings",
						component: () =>
							import("@/views/customers/bookings/packages/ViewPackage.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "packages/details/:uuid",
						name: "Package Bookings Detail",
						component: () =>
							import("@/views/customers/bookings/packages/PackageDetails.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "activities/:uuid",
						name: "Activities Bookings",
						component: () =>
							import(
								"@/views/customers/bookings/activities/ViewActivities.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "activities/details/:uuid",
						name: "Activities Bookings Detail",
						component: () =>
							import(
								"@/views/customers/bookings/activities/ActivitiesDetails.vue"
							),
						meta: {
							hide: true,
						},
					},
					{
						path: "ferry/:uuid",
						name: "Ferry Bookings",
						component: () =>
							import("@/views/customers/bookings/ferry/ViewFerry.vue"),
						meta: {
							hide: true,
						},
					},
					{
						path: "ferry/details/:uuid",
						name: "Ferry Bookings Detail",
						component: () =>
							import("@/views/customers/bookings/ferry/FerryDetails.vue"),
						meta: {
							hide: true,
						},
					},
				],
			},
			//CUSTOMERS

			//PROFILE
			{
				path: "profile",
				name: "Profile",
				component: () => import("@/views/profile/EditProfile.vue"),
				meta: {
					hide: true,
				},
			},
			//PROFILE

			//CHANGE PASSWORD
			{
				path: "change_password",
				name: "Change Password",
				component: () => import("@/views/password/ChangePassword.vue"),
				meta: {
					hide: true,
				},
			},
			//CHANGE PASSWORD
		],
	},
	{
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import("@/views/404.vue"),
	},
	{
		path: "/error",
		name: "error",
		component: () => import("@/views/error.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});
router.beforeEach((to, from, next) => {
	const titleText = to.name;
	const words = titleText.split(" ");
	const wordslength = words.length;
	for (let i = 0; i < wordslength; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}

	document.title = "OCI Travels  - " + words;
	next();
});

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
