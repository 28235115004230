import "animate.css";
import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import VueFlatPickr from "vue-flatpickr-component";
import VueGoodTablePlugin from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import vClickOutside from "click-outside-vue3";
import App from "./App.vue";
import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import axios from "axios";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueScrollTo from "vue-scrollto";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
	document.body.classList.add("dark");
	store.state.theme = "dark";
	store.state.isDark = true;
} else {
	document.body.classList.add("light");
	store.state.theme = "light";
	store.state.isDark = false;
}
if (localStorage.semiDark === "true") {
	document.body.classList.add("semi-dark");
	store.state.semidark = true;
	store.state.semiDarkTheme = "semi-dark";
} else {
	document.body.classList.add("semi-light");
	store.state.semidark = false;
	store.state.semiDarkTheme = "semi-light";
}
// check loacl storege for menuLayout
if (localStorage.menuLayout === "horizontal") {
	store.state.menuLayout = "horizontal";
} else {
	store.state.menuLayout = "vertical";
}

// check skin  for localstorage
if (localStorage.skin === "bordered") {
	store.state.skin = "bordered";
	document.body.classList.add("skin--bordered");
} else {
	store.state.skin = "default";
	document.body.classList.add("skin--default");
}
// check direction for localstorage
if (localStorage.direction === "true") {
	store.state.direction = true;

	document.documentElement.setAttribute("dir", "rtl");
} else {
	store.state.direction = false;
	document.documentElement.setAttribute("dir", "ltr");
}

async function unsetUser() {
	// Step 1: Retrieve the user object from localStorage
	const userString = localStorage.getItem("user");
	// Check if the user object exists in localStorage
	if (userString) {
		// Step 2: Parse the user object from the string
		const user = JSON.parse(userString);
		// Step 3: Update the "token" property to null
		user.token = null;
		// Save the modified user object back to localStorage
		localStorage.setItem("user", JSON.stringify(user));
	} else {
		const user = JSON.parse(userString);
		// Step 3: Update the "token" property to null
		user.token = null;
		// Save the modified user object back to localStorage
		localStorage.setItem("user", JSON.stringify(user));
	}
}
// Define the authentication function
async function authentication() {
	try {
		// Retrieve token from local storage
		const tokenItemString = localStorage.getItem("user");

		// Parse the string to get the user object
		const localStorageObject = JSON.parse(tokenItemString);

		// Retrieve the token from the user object
		const token = localStorageObject.token;
		// const token = null;

		// console.log(token);
		// Redirect to "/logout" if token is absent
		if (!token) {
			// unsetUser();
			// router.push("/logout");
		} else {
			axios.defaults.baseURL = process.env.VUE_APP_API_URL;

			// Set up axios interceptors for common headers and error handling
			axios.interceptors.request.use((config) => {
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
				return config;
			});

			axios.interceptors.response.use(
				(response) => response,
				(error) => {
					if (error.response && error.response.status === 401) {
						// Handle unauthorized access, for example, redirect to login
						//unsetUser();
						//router.push("/logout");
					}
					return Promise.reject(error);
				}
			);

			// Make authentication request
			const response = await axios.post("authentication");

			if (response.status === 200) {
				// console.log("AUTHORIZED");
			} else {
				unsetUser();
				router.push("/logout");
			}
		}
	} catch (error) {
		// unsetUser();
		// router.push("/logout");
		// Handle authentication errors
		// console.error("Authentication error:", error);
		// throw error;
	}
}

// Define the bound authentication function
async function boundAuthentication() {
	try {
		await authentication.call(this);
	} catch (error) {
		// console.error("Authentication error:", error);
	}
}

// Set up Vue Router navigation guards
router.beforeEach(async (to, from, next) => {
	// Call boundAuthentication before each route change
	await boundAuthentication.call(this);

	// Continue with the navigation
	next();
});

// vue use
createApp(App)
	.use(store)
	.use(VueSweetalert2)
	.use(Toast, {
		toastClassName: "dashcode-toast",
		bodyClassName: "dashcode-toast-body",
	})
	.use(createPinia())
	.use(pinia)
	.use(router)
	.use(VueClickAway)
	.use(vClickOutside)
	.use(VueTippy)
	.use(VueFlatPickr)
	.use(VueGoodTablePlugin)
	.use(VueApexCharts)
	.use(VCalendar)
	.use(VueScrollTo)
	.mount("#app");
